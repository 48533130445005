primary = #001029
gold = #fabc3c
green = #00ff5a
lightblue = #f2fdfd


border-radius()
  -webkit-border-radius: arguments
  -moz-border-radius: arguments
  border-radius: arguments

button()
  background-color green
  color primary

  &:hover
    background-color lighten(green, 5)

  &:focus
    background-color darken(green, 5)


input, textarea, select
  border: 1px solid primary
